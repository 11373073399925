
import { useEffect, useState, useCallback } from "react"
import { useParams, useLocation, useNavigate, Link } from "react-router-dom"

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons'

import {
    DiventrySide
} from '../../../../Templates/Layout'

import { General } from './General'
import { Advanced } from './Advanced'
import { Permissions } from './Permissions'
import { Security } from './Security'
import { Allowlist, Blocklist } from './ABList'
// import { Connect } from './Connect'

const pages = {
    'general': {
        key: 'general',
        title: "General",
        ref: General
    },
    'security': {
        key: 'security',
        title: "Security policies",
        ref: Security
    },
    'allowlist': {
        key: 'allowlist',
        title: "Allow list",
        ref: Allowlist
    },
    'blocklist': {
        key: 'blocklist',
        title: "Block list",
        ref: Blocklist
    },
    'permissions': {
        key: 'permissions',
        title: "User management",
        ref: Permissions
    },
    'advanced': {
        key: 'advanced',
        title: "Advanced",
        ref: Advanced
    }

    // 'connect': {
    //     key: 'connect',
    //     title: "DoHzel Connect",
    //     ref: Connect
    // },
}


export function Settings({ kernel, updateProfile }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const [loaded, setLoaded] = useState(true)
    const [page, setPage] = useState(null)
    const [content, setContent] = useState(null)

    useEffect(() => {
        if (params.page1 !== "settings")
            return

        if (!params.page2) {
            navigate(`/dohzel/profile/${params.id}/settings/general`)
        }
        else {
            const el = pages[params.page2]
            if (!el)
                return (navigate(`/dohzel/profile/${params.id}/settings/general`))
            setPage(el)
            setContent(<el.ref kernel={kernel} updateProfile={updateProfile} forwardId={params.id}/>)
        }
    }, [params])

    return (
        <DiventrySide
            card={true}
            selectedKeys={[params.page2]}
            menu={Object.values(pages).map((item) => {
                return ({
                    key: item.key,
                    label: <Link>{item.title}</Link>,
                })
            })}
            onClick={(e) => {
                navigate(`/dohzel/profile/${params.id}/settings/${e.key}`)
            }}
        >
            {content}
        </DiventrySide>
    )
}